import React, { Dispatch, SetStateAction, useCallback } from "react";
import Select, { components } from "react-select";
import { useField } from "formik";
import { OptionProps, SingleValueProps } from "react-select";
import { Text } from "../Text";
import debounce from "lodash/debounce";
import Image from "next/image";
import { BASE_COLORS } from "@/theme";
import { background, height, width } from "styled-system";
import RushkinkLogo from "@/public/assets/images/pngimages/rushlink.png";
import { useTheme } from "styled-components";

// Generic type for options
export interface Option {
  value: string;
  label: string;
  image: string;
  location: string;
}

const customTheme = (theme: any) => ({
  ...theme,
  borderRadius: 0,
  colors: {
    ...theme.colors,
    primary25: `${BASE_COLORS.primaryLight}`,
    primary: `${BASE_COLORS.primary}`,
  },
});

export interface AsyncSelectProps<T extends Option> {
  options: T[];
  onChange?: (selected: T | null) => void;
  placeholder?: string;
  name?: string;
  value?: T | null;
  label?: string;
  loading?: boolean;
  handleSetSearchTerm: (keyword: string) => void;
  error?: string;
  filterExternalIds?: string[];
}

export function StyledAsyncSelectV2<T extends Option>({
  options,
  onChange,
  placeholder,
  loading,
  name,
  handleSetSearchTerm,
  filterExternalIds,
  value,
}: AsyncSelectProps<T>) {
  const theme = useTheme();

  const customStyles = {
    control: (baseStyles: any, state: any) => ({
      ...baseStyles,
      border: "none",
      borderBottom: `1px solid ${BASE_COLORS.grey}`,
      height: "50px",
      width: "100%",
      background: `${theme.colors.background}`,
    }),
    singleValue: (baseStyles: any) => ({
      ...baseStyles,
      display: "flex",
      alignItems: "center",
    }),
    input: (baseStyles: any) => ({
      ...baseStyles,
      color: theme.colors.foreground, // Change the text color while typing here
    }),
  };
  // const filterOptions = (inputValue: string) => {
  //   return options?.filter((option) =>
  //     option.label.toLowerCase().includes(inputValue.toLowerCase())
  //   );
  // };
  const filterExternalId = (value: string) => {
    if (filterExternalIds?.length) {
      const hasItem = filterExternalIds.some((v) => v === value);
      return !hasItem;
    }
    return true;
  };

  const debouncedHandleSetSearchTerm = useCallback(
    debounce((value: string) => {
      handleSetSearchTerm(value);
    }, 300),
    [handleSetSearchTerm]
  );

  const CustomOption = ({ innerProps, label, data }: OptionProps<T>) => (
    <div
      {...innerProps}
      style={{
        display: "flex",
        alignItems: "center",
        padding: "8px",
        cursor: "pointer",
        background: theme.colors.background,
        // border: "1px solid white",
        border: `1px solid ${BASE_COLORS.lightGreyTransparent}`,
      }}
    >
      <Image
        src={data.image === null ? RushkinkLogo : data.image}
        alt={label}
        width={30}
        height={30}
        style={{ marginRight: "10px", borderRadius: "50%" }}
        objectFit="contain"
      />
      <div style={{ display: "flex", flexDirection: "column" }}>
        <Text color={"foreground"}>{label}</Text>
        <Text size={13} color="grey">
          {data.location}
        </Text>
      </div>
    </div>
  );

  const CustomSingleValue = ({ children, ...props }: SingleValueProps<T>) => (
    <components.SingleValue {...props}>
      <div
        style={{
          display: "flex",
          alignItems: "center",
          color: theme.colors.foreground,
        }}
      >
        {props.data.image ? (
          <>
            <Image
              src={props.data.image === null ? RushkinkLogo : props.data.image}
              alt={props.data.label}
              width={20}
              height={20}
              style={{ marginRight: "10px", borderRadius: "50%" }}
              objectFit="contain"
            />
          </>
        ) : null}
        {children}
      </div>
    </components.SingleValue>
  );

  return (
    <Select<T>
      components={{
        Option: CustomOption,
        SingleValue: CustomSingleValue,
      }}
      options={options}
      isLoading={loading}
      onChange={onChange}
      onInputChange={debouncedHandleSetSearchTerm}
      placeholder={placeholder}
      name={name}
      value={value}
      styles={customStyles}
      theme={customTheme}
      filterOption={(o) => filterExternalId(o.value)}
    />
  );
}
