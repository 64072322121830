import { useField } from "formik";
import {
  AsyncSelectProps,
  Option,
} from "../styled/StyledAsyncSelect/StyledAsyncSelect";

import { AsyncSelect } from "../AsyncSelect";

type FormAsyncSelectProps<T extends Option> = {
  name: string;
} & AsyncSelectProps<T>;

export function FormAsyncSelect<T extends Option>({
  name,
  ...props
}: FormAsyncSelectProps<T>) {
  const [field, meta, helpers] = useField(name);

  const handleChange = (selected: T | null) => {
    helpers.setValue(selected);
    if (props.onChange) {
      props.onChange(selected);
    }
  };
  const error = meta.touched && meta.error ? meta.error : undefined;

  return (
    <AsyncSelect
      name={name}
      error={error}
      onChange={handleChange}
      value={field.value}
      {...props}
    />
  );
}
