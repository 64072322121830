import { Text } from "../styled";
import {
  AsyncSelectProps,
  StyledAsyncSelect,
  Option,
} from "../styled/StyledAsyncSelect/StyledAsyncSelect";
import { Column } from "../styled";
import { StyledAsyncSelectV2 } from "../styled/StyledAsyncSelect/StyledAsyncSelectV2";

export function AsyncSelect<T extends Option>({
  label,
  error,
  ...props
}: AsyncSelectProps<T>) {
  return (
    <Column gap="11.5px" width="100%">
      {label ? (
        <Text size={["14px", "16px"]} weight="bold" color="foreground">
          {label}
        </Text>
      ) : null}
      {/* <StyledAsyncSelect<T> {...props} /> */}
      <StyledAsyncSelectV2<T> {...props} />
      {error && <Text style={{ color: "red" }}>{error}</Text>}
    </Column>
  );
}
