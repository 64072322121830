import {
  createContext,
  Dispatch,
  ReactNode,
  SetStateAction,
  useEffect,
  useState,
} from "react";

type AppStateType = {
  theme: string;
  setTheme: Dispatch<SetStateAction<string>>;
};

export const AppState = createContext<AppStateType>({} as AppStateType);

export const AppStateProvider = ({ children }: { children: ReactNode }) => {
  // const [theme, setTheme] = useState(localStorage.getItem("theme") || "dark");

  const [theme, setTheme] = useState('dark'); // Default to 'dark'

  useEffect(() => {
    // This runs only on the client side
    const storedTheme = localStorage.getItem('theme');
    if (storedTheme) {
      setTheme(storedTheme);
    }
  }, []);

  return (
    <AppState.Provider value={{ theme, setTheme }}>
      {children}
    </AppState.Provider>
  );
};
