import { graphql } from "@/gql";
import { useQuery } from "@apollo/client";

export const GET_SCHOOLS = graphql(`
  query SearchSchools($input: SearchSchoolCriteria!) {
    searchSchools(input: $input) {
      name
      logo
      id
      alias
      location
      followerCount
    }
  }
`);

export const useGetSchools = (keywords: string, take?: number) => {
  const response = useQuery(GET_SCHOOLS, {
    variables: {
      input: {
        keywords: keywords,
        take: take ? take : 10,
      },
    },
  });
  return response;
};
