import dynamic from "next/dynamic";

export const useLottiePlayer = () => {
  const DynamicLottiePlayer = dynamic(
    () => import("@lottiefiles/react-lottie-player").then((mod) => mod.Player),
    { ssr: false }
  );

  return DynamicLottiePlayer;
};
